<template>
	<div
		class="full-height"
	>
		<div>
			<h6 class="justify-space-between">
				영업점
			</h6>
			<div class="top-line-identify text-left font-weight-bold box pa-10 bg-eee">
				{{ upper_name }}
			</div>
		</div>
		<div>
			<h6 class="text-left">매출 합계</h6>
			<table class="table top-line-identify">
				<thead>
					<tr>
						<th>매출 시작일</th>
						<th>매출 종료일</th>
						<th>정산 기준일</th>
						<th>승인금액</th>
						<th>정산금액</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{ item.fromDate }}</td>
						<td>{{ item.toDate }}</td>
						<td>{{ item.calDate }}</td>
						<td>{{ summary.totalApprovalAmount }}</td>
						<td>{{ summary.totalCalAmount }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="mt-10">
			<h6 class="justify-space-between">
				매출 내역
				<div>
				<button
					class="box mr-10 pa-4-10 size-px-12"
					@click="toExcel"
				><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
				<select
					class="pa-5-10  size-px-12"
					v-model="search.size"
					@change="getSearch"
				>
					<option
						v-for="cnt in codes.list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>
				</div>

			</h6>
			<table class="table top-line-identify table-even">
				<colgroup>
					<col width="60px" />

				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>승인일시</th>
						<th>정산 기준일</th>
						<th>가맹점</th>
						<th>승인번호</th>

						<th>할부</th>
						<th>수수료율</th>
						<th>승인금액</th>
						<th>정산금액</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in item_list"
						:key="'item_' + index"
					>
						<td>{{  item.idx }}</td>
						<td>{{  item.approvalDate }}</td>
						<td>{{  item.calDate }}</td>
						<td class="text-left">{{  item.shopName }}</td>
						<td>{{ item.approvalCode}}</td>

						<td></td>
						<td>{{  item.fee }}</td>
						<td class="text-right">{{  item.approvalAmount }}</td>
						<td class="text-right">{{ item.calAmount}}</td>
					</tr>
				</tbody>
			</table>

			<pagination
				:options="search"

				@click="getSearch"
			></pagination>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>
	</div>
</template>

<script>
	import Pagination from "@/components/Pagination";
	import Excel from "@/components/Excel";

	export default {
		name: 'QuickSettlementDetailList'
		,components: {Excel, Pagination}
		,props: ['item', 'user']
		,data: function(){
			return {
				items: []
				,search: {
					page: 1
					,size: 10
					,sDate: this.item.sDate
					,eDate: this.item.eDate
					, distributorIdx: this.user.distributorIdx
					, branchIdx: this.user.branchIdx
					, agencyIdx: this.user.agencyIdx
					, resellerIdx: this.user.resellerIdx
				}
				,summary: {
					totalApprovalAmount: 0
					,totalCalAmount: 0
				}
				,is_excel: false
				,excel_data: {
					name: '배달비 정산 상세 내역'
					,header: [
						{ key: 0, name: 'NO', column: 'calIdx'}
						,{ key: 0, name: '승인 일시', column: 'approvalDate'}
						,{ key: 0, name: '정산 기준일', column: 'calDate'}
						,{ key: 0, name: '가맹점', column: 'shopName'}
						,{ key: 0, name: '승인번호', column: 'approvalCode'}
						,{ key: 0, name: '할부', column: 'installment'}
						,{ key: 0, name: '수수료율', column: 'fee'}
						,{ key: 0, name: '승인금액', column: 'approvalAmount'}
						,{ key: 0, name: '정산금액', column: 'calAmount'}
					]
					,content: null
				}
				,upperBranchList: {}
			}
		}
		,computed: {
			item_list: function(){
				return this.items.filter(function(item){
					return item
				})
			}
			,upper_name: function(){
				let name = ''

				if(this.upperBranchList.branchName){
					name = this.upperBranchList.branchName
				}
				if(this.upperBranchList.distributorName){
					name += ' > ' + this.upperBranchList.distributorName
				}
				if(this.upperBranchList.agencyName){
					name += ' > ' + this.upperBranchList.agencyName
				}
				if(this.upperBranchList.resellerName){
					name += ' > ' + this.upperBranchList.resellerName
				}

				return name
			}
		}
		,methods: {
			getData: async function(){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'get'
						,url: 'quick/calculate/history/' + this.item.calIdx + '/' + this.item.shopIdx
						,data: this.search
					})

					if(result.success){
						this.items = result.data.tableList.data
						this.$set(this.search, 'total_count', result.data.tableList.totalCount)
						this.summary = result.data.statistics
						this.upperBranchList = result.data.upperBranchList
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}

			,toExcel: function(){
				this.excel_data.content = this.items
				this.is_excel = true
			}
			,getSearch: function(page){
				if(page){
					this.search.page = page
				}
				this.getData()
			}
		}
		,created() {
			this.getData()
		}
	}
</script>